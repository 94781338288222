/* General Page Styles */
.landing-page {
  font-family: "Arial", sans-serif;
  color: #333;
  background-color: #f8f9fa;
}

/* Hero Section */
.hero {
  position: relative;
  background: url('../../../images/landingimage.jpg') center center/cover no-repeat;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  padding: 20px;
}

.hero-content {
  background-color: rgba(0, 0, 0, 0.4);
  padding: 30px;
  border-radius: 8px;
}

.hero h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.cta-buttons .btn {
  padding: 10px 20px;
  font-size: 1rem;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-btn.join-now {
  background-color: #2d3194;
  color: white;
}

.cta-btn.browse-courses {
  background-color: #f05358;
  color: white;
}

.cta-btn.browse-courses:hover {
  background-color: #f04358;
  color: white;
}

.cta-btn:hover {
  background-color: #1d3194;
}

/* Feature Cards Section */
.features {
  padding: 50px 0;
  text-align: center;
  background-color: #f4f7fb;
}

.features h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.feature-cards {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.feature-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  position: relative;
  width: 250px;
  margin-bottom: 20px;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

.feature-card h3 {
  font-size: 1.25rem;
  margin: 1rem 0;
}

.feature-card p {
  font-size: 0.9rem;
  color: #6c757d;
}

.feature-card .card-icon {
  position: absolute;
  top: -15px;
  left: 15px;
  background: linear-gradient(45deg, #6a11cb, #2575fc);
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
}

/* Level Types Section */
.level-types {
  padding: 50px 0;
}

/* Course Cards Container */
.course-cards-container {
  margin: 20px auto;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  max-width: 1200px;
  justify-content: center;
}

.level-type {
  margin-bottom: 40px;
  min-width: 33%;
}

.level-header {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #2d3194;
  text-align: center;
}

.course-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.course-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  text-align: center;
  min-width: 33%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.course-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

.start-learning-btn {
  background-color: #f05358;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.start-learning-btn:hover {
  background-color: #f04358;
  color: white
}

/* Responsive Styles */
@media (max-width: 768px) {
  .course-cards {
    flex-direction: column;
    align-items: center;
  }

  .course-card {
    width: 100%;
  }
}