/* Container for all units */
.units-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 16px;
  }
  
  /* Each unit card */
  .unit-card {
    flex: 0 0 calc(33.333% - 16px);
    background: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .unit-card:hover {
    transform: scale(1.02);
  }
  
  /* Unit title */
  .unit-title {
    font-size: 1.5rem;
    color: $primary;
    margin-bottom: 12px;
    color: #333;
    text-align: center;
    font-weight: bold;
  }
  
  /* Topic list */
  .topic-list {
    list-style: none;
    padding: 0;
    margin: 0;
    min-height: 200px;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  
  .unit-card.expanded .topic-list {
    max-height: none;
  }
  
  .topic-list li {
    background: #ffffff;
    margin: 4px 0;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.05);
    font-size: 1rem;
    color: #555;
  }
  
  /* View More button */
  .view-more {
    margin-top: 8px;
    text-align: center;
    color: #007bff;
    cursor: pointer;
    font-weight: bold;
  }
  
  /* Expanded view */
  .expanded-view {
    display: none;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin-top: 16px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  }
  .expanded-view.active {
    display: block;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .unit-card {
      flex: 1 1 calc(50% - 16px); /* Two per row on tablets */
    }
  }
  
  @media (max-width: 480px) {
    .unit-card {
      flex: 1 1 100%; /* One per row on small screens */
    }
  }
  