.paginated-row {
  display: flex;
  flex-wrap: wrap;
  // margin-left: -0.5rem;
  // margin-right: -0.5rem;
}

.item-container {
  // min-width: 19.9%;
  // max-width: 20%;
  //padding: 0.25rem 0.5rem 1rem 0.5rem;
  -webkit-transition: $transition-custom;
  -moz-transition: $transition-custom;
  -o-transition: $transition-custom;
  transition: $transition-custom;

  // @media (max-width: 1200px) {
  //   min-width: 24.9%;
  //   max-width: 25%;
  // }

  //   @media (max-width: 992px) {
  //     min-width: 33.33%;
  //     max-width: 33.34%;
  //   }

  //   @media (max-width: 768px) {
  //     min-width: 49.9%;
  //     max-width: 50%;
  //   }
}




.item-card {
  width: 100%;
  //box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  .image-container {
    width: 100%;
    padding-top: 100%;
    background-color: #fefefe !important;
    position: relative;
  }

  .video-container {
    width: 100%;
    // padding-top: 56.25%;
    background-color: #fefefe !important;
    position: relative;
    // margin-bottom: 0.5rem;
  }

  .item-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: -100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 25% 10%;
  }

  .item-info {
    border-top: 1px solid #fff;
    background-color: #fff;
    padding: 0.5rem;

    .item-category {
      color: $primary;
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: small;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .item-name {
      color: $gray-700;
      margin-top: 0px;
      margin-bottom: 0.05rem;
      font-size: medium;
      //font-weight:;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.ngamia-advert {
  width: 100%;
  padding-top: 75%;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background-color: $white;

  @media (max-width: 768px) {
    min-height: 120px;
    max-height: 120px;
  }

  .image-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 0% 50%;
    }
  }
}

.premium-crown {
  background-color: #0009;
  border-radius: 50%;
  overflow: hidden;
  height: 1.8rem;
  width: 1.8rem;
  position: absolute;
  top: 5px;
  right: 5px;
}

.dots-container {
  position: absolute;
  bottom: 10%;
  left: 75%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 2px;


}

.dot {
  width: 3px;
  height: 3px;
  background-color: #000;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}



// .dot:hover {
//   background-color: #888;
// }

// .dot.active {
//   background-color: #333;
// }

.dots-container:hover .info {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s;
  opacity: 1;
  display: block;
}

.popup {
  position: absolute;
  display: none;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.popup-content {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.popup-btn {
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  text-align: left;
}

.popup-btn:hover {
  background-color: #f0f0f0;
}